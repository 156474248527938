import React from 'react';

import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { MediaCover } from '../../../../../components-shared/MediaCover';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { StepViewHidden } from '../StepViewHidden';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  getSectionDescription,
  isSectionLocked,
} from '../../../../../selectors/sections';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';
import { StepViewBreadcrumbs } from '../StepViewBreadcrumbs';
import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';
import { classes, st } from './SidebarSectionView.st.css';

export interface ISidebarSectionView {
  currentSectionId: string;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
  goToMobileList(): void;
}

export const SidebarSectionView: FCWithChildren<ISidebarSectionView> = (
  props,
) => {
  const {
    listParticipantSections,
    isListParticipantSectionsRequestInProgress,
    requestParticipantSection,
  } = useSections();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const section = listParticipantSections.find(
    (s) => s.id === props.currentSectionId,
  );
  const description = getSectionDescription(section);
  const media = description?.media;
  const details = description?.details;
  const isDelayedDetails = details === undefined; // only `undefined` is suitable here

  React.useEffect(() => {
    if (isDelayedDetails) {
      requestParticipantSection?.(props.currentSectionId);
    }
  }, [props.currentSectionId]);

  useSingleEndInteraction(
    FedopsInteraction.ViewSectionAtSidebarLayout,
    section?.id,
  );

  if (!section) {
    return null;
  }

  const isHideSection =
    experiments.enabled(Challenges.enableDripContent) &&
    isSectionLocked(section);

  if (isHideSection) {
    return (
      <StepViewHidden
        isWeekFixForCTA={false}
        date={section.transitions[0].waitingDate.startDate}
        titleKey="live.section.section-locked.title"
        goToCurrentStep={props.goToCurrentStep}
        descriptionKey="live.section.section-locked.description"
      />
    );
  }

  return isListParticipantSectionsRequestInProgress ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={props.currentSectionId}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {isMobile && (
        <StepViewBreadcrumbs goToMobileList={props.goToMobileList} />
      )}
      <Fullscreen pageRef={props.pageRef} />

      <TextWithDirection>
        <h3 className={classes.stepViewTitle}>{description?.title}</h3>
      </TextWithDirection>

      <MediaCover
        fit="contain"
        media={media}
        className={classes.sectionMedia}
      />

      {isDelayedDetails ? (
        <Spinner
          type={SpinnerTypes.slim}
          isCentered={!isMobile && !media}
          className={media ? classes.spinnerWithContent : classes.spinner}
        />
      ) : (
        <div className={classes.sectionDescription}>
          <Ricos
            key={section?.id}
            details={details}
            context="section_description"
            contentId={section?.id}
          />
        </div>
      )}
    </div>
  );
};
